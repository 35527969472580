// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
// mobx
import { observer } from "mobx-react";
import * as AppClientService from "../services/appClient";
import {
  Button,
  Popup,
  Confirm,
  Table,
  Icon,
  Modal,
  Header,
  Placeholder,
  Grid,
} from "semantic-ui-react";
import AppClientForm from "../components/AppClientForm";
import AppClientSecret from "../components/AppClientSecret";
import AppClientToken from "../components/AppClientToken";
import { fragments } from "services/get-fragments";

export default observer(() => {
  const [appClients, setAppClients] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [modalSecretOpen, setModalSecretOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalTokenOpen, setModalTokenOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const confirmDelete = async (id) => {
    setModalOpen(true);
    setId(id);
  };

  const handleDelete = async () => {
    setModalOpen(false);
    try {
      await AppClientService.deleteById(id);
      const appClientToDelete = [...appClients];
      _.remove(appClientToDelete, function (n) {
        return n.ClientId === id;
      });
      setAppClients(appClientToDelete);
    } catch (error) {
      alert(error.message);
    }
  };

  // Initial load
  useEffect(() => {
    (async () => {
      try {
        const out = await AppClientService.List();
        setAppClients(out.Items);
      } catch (error) {
        alert(error.message)
      }
      setLoading(false);
    })();
  }, [modalSecretOpen, modalEditOpen]);

  return (
    <>
      <Confirm
        open={modalOpen}
        header={fragments.AppClient.deleteAppClient}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleDelete}
        cancelButton={fragments.common.cancel}
        confirmButton={fragments.common.delete}
        content={fragments.AppClient.confirmDelete}
      />
      <Modal
        open={modalSecretOpen}
        onClose={() => setModalSecretOpen(false)}
        onOpen={() => setModalSecretOpen(true)}
        onCancel={() => setModalSecretOpen(false)}
      >
        <Modal.Header>{fragments.AppClient.clientSecret}</Modal.Header>
        <AppClientSecret setModalFormOpen={setModalSecretOpen} id={id} />
      </Modal>
      <Modal
        open={modalTokenOpen}
        onClose={() => setModalTokenOpen(false)}
        onOpen={() => setModalTokenOpen(true)}
        onCancel={() => setModalTokenOpen(false)}
      >
        <Modal.Header>{fragments.AppClient.generateTokenHeader}</Modal.Header>
        <AppClientToken setModalTokenOpen={setModalTokenOpen} id={id} />
      </Modal>
      <div style={{ padding: "2em", width: "100%" }}>
        <Header as="h1">{fragments.AppClient.title}</Header>
        <div style={{ float: "right", marginBottom: "1rem" }}>
          <Button.Group>
            <Modal
              open={modalEditOpen}
              onClose={() => setModalEditOpen(false)}
              onOpen={() => setModalEditOpen(true)}
              onCancel={() => setModalEditOpen(false)}
              trigger={
                <Button
                  primary
                  size="small"
                  style={{ float: "right" }}
                  onClick={() => setId(undefined)}
                >
                  {fragments.AppClient.create}
                </Button>
              }
            >
              <Modal.Header>
                {id ? fragments.AppClient.update : fragments.AppClient.create}
              </Modal.Header>
              <AppClientForm setModalFormOpen={setModalEditOpen} id={id} />
            </Modal>
          </Button.Group>
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing sorted="ascending">
                {fragments.AppClient.appName}
              </Table.HeaderCell>
              <Table.HeaderCell collapsing sorted="ascending">
                {fragments.AppClient.clientId}
              </Table.HeaderCell>
              <Table.HeaderCell collapsing sorted="ascending">
                {fragments.AppClient.clientSecret}
              </Table.HeaderCell>
              <Table.HeaderCell collapsing sorted="ascending">
                {fragments.AppClient.tokenValidity}
              </Table.HeaderCell>
              <Table.HeaderCell collapsing sorted="ascending">
                {fragments.common.created}
              </Table.HeaderCell>
              <Table.HeaderCell collapsing sorted="ascending">
                {fragments.common.action}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                {[1, 2, 3, 4, 5, 6].map((n) => (
                  <Table.Cell key={n}>
                    <Placeholder fluid>&nbsp;</Placeholder>
                  </Table.Cell>
                ))}
              </Table.Row>
            ) : appClients.length > 0 ? (
              appClients.map((app) => (
                <Table.Row key={app.ClientId}>
                  <Table.Cell>{app.ClientName}</Table.Cell>
                  <Table.Cell>
                    <CopyToClipboard text={app.ClientId}>
                      <Button>
                        <Icon name="copy" /> {app.ClientId}
                      </Button>
                    </CopyToClipboard>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={() => {
                        setId(app.ClientId);
                        setModalSecretOpen(true);
                      }}
                    >
                      <Icon name="unhide" />
                      {fragments.common.show}
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Grid columns={2}>
                      <Grid.Column>
                        {fragments.AppClient.refresh}: <br />
                        {fragments.AppClient.id}: <br />
                        {fragments.AppClient.access}:
                      </Grid.Column>
                      <Grid.Column>
                        {`${app.RefreshTokenValidity} ${app.TokenValidityUnits.RefreshToken}`}
                        <br />
                        {`${app.IdTokenValidity} ${app.TokenValidityUnits.IdToken}`}
                        <br />
                        {`${app.AccessTokenValidity} ${app.TokenValidityUnits.AccessToken}`}
                      </Grid.Column>
                    </Grid>
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      content={new Date(app.CreationDate).toString()}
                      trigger={<div>{moment(app.CreationDate).fromNow()}</div>}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={() => {
                        setId(app.ClientId);
                        setModalEditOpen(true);
                      }}
                    >
                      <Icon name="edit" />
                      {fragments.common.edit}
                    </Button>
                    <Button onClick={() => confirmDelete(app.ClientId)}>
                      <Icon name="trash" />
                      {fragments.common.delete}
                    </Button>
                    <Button onClick={() => {
                      setId(app.ClientId);
                      setModalTokenOpen(true)
                    }}>
                      <Icon name="barcode" />
                      {fragments.AppClient.token}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan="6">
                  {fragments.AppClient.noAppClients}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </>
  );
});
