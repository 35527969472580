// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import * as AppClientService from "../services/appClient";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Icon, Container, Dimmer, Loader } from "semantic-ui-react";
import { fragments } from 'services/get-fragments'

export default (p) => {
  const { setModalFormOpen, id } = p;
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);

  // Initial load
  useEffect(() => {
    AppClientService.getSecret(id)
      .then((out) => {
        setClientSecret(out.ClientSecret);
      })
      .catch((error) => {
        alert(error.message)
        setModalFormOpen(false)
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <p>{fragments.AppClient.secretAdvice}</p>
      <CopyToClipboard text={clientSecret}>
        <Button primary>
          <Icon name="copy" /> {fragments.common.copy}
        </Button>
      </CopyToClipboard>
      <Button content={fragments.common.close} onClick={() => setModalFormOpen(false)} />
    </Container>
  );
};
