// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from "react";
import * as ApiTagService from "../../services/apiTag";

import {
  Button,
  Icon,
  Container,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { fragments } from 'services/get-fragments'

const defaultValues = {
  Tag: ""
};

export default (p) => {
  const { setModalFormOpen } = p;

  const [data, setData] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const handleChangeParams = (event) => {
    data[event.target.name] = event.target.value;
    setData({ ...data });
  };

  const handleCreate = async () => {
    try {
      await ApiTagService.Create({
        params: data,
      }).finally(() => setLoading(false));
      setModalFormOpen(false);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Form onSubmit={handleCreate}>
        <p>
          <b>{fragments.ApiTag.name}: </b>
        </p>
        <Form.Field>
          <input
            name="Tag"
            value={data.Tag}
            type="string"
            style={{ width: 100 }}
            onChange={handleChangeParams}
          />
        </Form.Field>
        <Container style={{ padding: "1em", textAlign: "right" }}>
          <Button type="submit" primary>
            <Icon name="save" /> Save
          </Button>
        </Container>
      </Form>
    </Container>
  );
};
