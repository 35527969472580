// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import homeImage from "../custom-content/home-image.png"

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// react-router
import { Link } from 'react-router-dom'

// semantic-ui
import { Header, Container, Image, Button, Grid, Divider } from 'semantic-ui-react'

export const HomePage = observer(() => (
  <>
    <Container style={{
      margin: 70,
      maxWidth: 400,
      backgroundColor: "white",
      padding: 60,
      borderRadius: 6, boxShadow: "0px 3px 9px rgba(0,0,0,0.17)",
      textAlign: 'center'
    }}>
      <Image centered size='medium' src={homeImage} />
      <Header>{fragments.Home.header}<br />
        {fragments.Home.tagline}</Header>
      <Grid columns={4}>
        <Grid.Column>
        </Grid.Column>
        <Grid.Column>
          <Link to='/getting-started' data-testid='gettingStartedLink'>
            <Button primary fluid>{fragments.Home.gettingStartedButton}</Button>
          </Link>
        </Grid.Column>
        <Grid.Column>
          <Link to='/apis' data-testid='apiListLink'>
            <Button secondary fluid>{fragments.Home.apiListButton}</Button>
          </Link></Grid.Column>
      </Grid>
      <Divider />
      <Container fluid text textAlign='justified'>
        <fragments.Home.jsx />
      </Container>
    </Container>
  </>
))

export default HomePage
