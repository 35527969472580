import React, { useState } from "react";
import { Auth } from "../services/AmplifyAuth";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Dimmer,
  Loader,
  Icon,
  Message,
} from "semantic-ui-react";
import nav_logo from "../custom-content/nav-logo.svg";
import { Link, useParams } from "react-router-dom";
import { fragments } from "../services/get-fragments"

export default () => {
  const { email } = useParams();
  const [username, setUsername] = useState(email || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [passwordLowerCase, setPasswordLowerCase] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordLong12, setPasswordLong12] = useState(false);
  const [receiveUsername, setReceiveUsername] = useState(email);

  const handleSubmit = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email: username,
          },
        });
        setWaitingForCode(true);
      } catch (error) {
        alert(`${fragments.Session.errorSignUp}: ${error.message}`);
      }
      setLoading(false);
    })();
  };

  const handleSubmitCode = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await Auth.confirmSignUp(username, code);
        alert(fragments.Session.registerSuccess);
        window.location = "/explorer";
      } catch (error) {
        alert(`${fragments.Session.errorConfirmationCode}: ${error.message}`);
        setLoading(false);
      }
    })();
  };

  const handleChangeUser = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    const regExpLowerCase = /[a-z]+/g;
    const regExpNumber = /\d/g;
    const regExpLong12 = /.{12,}/g;
    setPasswordLowerCase(regExpLowerCase.test(event.target.value));
    setPasswordNumber(regExpNumber.test(event.target.value));
    setPasswordLong12(regExpLong12.test(event.target.value));
    setPassword(event.target.value);
  };

  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const pasteClipboard = async () => {
    const clipboardContent = await navigator.clipboard.readText();
    setCode(clipboardContent);
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh", backgroundColor: "#EEE", marginTop: 0 }}
      columns={2}
    >
      <Grid.Column
        verticalAlign="middle"
        style={{
          minHeight: "50vh",
          maxWidth: 200,
          backgroundColor: "gray",
          padding: 40,
        }}
      >
        <Image src={nav_logo} /> <h1>Welcome</h1>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        verticalAlign="middle"
        style={{
          minHeight: "50vh",
          maxWidth: 400,
          backgroundColor: "white",
          padding: 40,
        }}
      >
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        {!waitingForCode && (
          <Form onSubmit={handleSubmit}>
            <Header as="h1" textAlign="center">
              Register
            </Header>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={fragments.Session.email}
              name="user"
              type="email"
              value={username}
              onChange={handleChangeUser}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={fragments.Session.password}
              name="password"
              type="password"
              value={password}
              onChange={handleChangePassword}
            />
            <div style={{ color: passwordLowerCase ? "green" : "red" }}>
              <Icon name={passwordLowerCase ? "checkmark" : "x"} />
              {fragments.Session.passwordPattern1}
            </div>
            <div style={{ color: passwordNumber ? "green" : "red" }}>
              <Icon name={passwordNumber ? "checkmark" : "x"} />
              {fragments.Session.passwordPattern2}
            </div>
            <div style={{ color: passwordLong12 ? "green" : "red" }}>
              <Icon name={passwordLong12 ? "checkmark" : "x"} />
              {fragments.Session.passwordPattern3}
            </div>
            <br />
            <Button primary fluid>
              {fragments.Session.sendRegistrationCode}
            </Button>
            <br />
            <Button
              fluid
              onClick={() => {
                if (username.length > 0) setReceiveUsername(true);
                setWaitingForCode(true);
              }}
            >
              {fragments.Session.iAlreadyHaveCode}
            </Button>
          </Form>
        )}
        {waitingForCode && (
          <Form onSubmit={handleSubmitCode}>
            <Header as="h1" textAlign="center">
              {fragments.Session.registrationCode}
            </Header>
            {!receiveUsername ? (
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder={fragments.Session.email}
                name="user"
                type="email"
                value={username}
                onChange={handleChangeUser}
              />
            ) : (
              <Message>
                <Icon name="user" />
                {fragments.Session.username}: {username}
                <Icon
                  name="delete"
                  onClick={() => {
                    setReceiveUsername(false);
                    setUsername("");
                  }}
                />
              </Message>
            )}
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={fragments.Session.code}
              name="code"
              type="number"
              value={code}
              onChange={handleChangeCode}
              action={{
                icon: "paste",
                labelPosition: "right",
                content: fragments.Session.paste,
                onClick: (event) => {
                  event.preventDefault();
                  pasteClipboard();
                },
              }}
            />
            <Button primary fluid>
              {fragments.Session.confirmCode}
            </Button>
            <Message>
              <Link to="/codeRequest">{fragments.Session.requestNewCode}</Link>
            </Message>
          </Form>
        )}
      </Grid.Column>
    </Grid>
  );
};
