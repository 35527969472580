import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api'

// const getAccountsWithFilter = async filter => {
//   const apiGatewayClient = await getApiGatewayClient()
//   const response = await apiGatewayClient.get(
//     '/appClient',
//     undefined,
//     undefined,
//     { queryParams: { filter } }
//   )
//   return response.data.accounts
// }

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const AdminList = () => fetch('get', url`/admin/cognitoGroup`)
export const AdminDelete = (data) => fetch('delete', url`/admin/cognitoGroup`, data)
export const AdminGet = (GroupName) => fetch('get', url`/admin/cognitoGroup/${GroupName}`)
// export const AdminUpdate = (data) => fetch('put', url`/admin/cognitoGroup`, data)
export const Create = data => fetch('post', '/admin/cognitoGroup', data)
export const AdminRemoveUserFromGroup = (GroupName, UserId) => fetch('delete', url`/admin/cognitoGroup/${GroupName}/${UserId}`)
export const AdminAddUserToGroup = (data) => fetch('post', url`/admin/cognitoGroup/addUser`, data)
export const AdminAddApiToGroup = (data) => fetch('post', url`/admin/cognitoGroup/addApi`, data)
export const AdminGetApiFromGroup = (GroupName) => fetch('get', url`/admin/cognitoGroup/getApi/${GroupName}`)
export const AdminRemoveApiFromGroup = (GroupName, apiId) => fetch('delete', url`/admin/cognitoGroup/removeApi/${GroupName}/${apiId}`)