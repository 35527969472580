import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api'
import axios from 'axios';

// const getAccountsWithFilter = async filter => {
//   const apiGatewayClient = await getApiGatewayClient()
//   const response = await apiGatewayClient.get(
//     '/appClient',
//     undefined,
//     undefined,
//     { queryParams: { filter } }
//   )
//   return response.data.accounts
// }

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const AdminList = () => fetch('get', url`/admin/appClient`)
export const AdminDelete = (data) => fetch('delete', url`/admin/appClient`, data)
export const AdminGet = (UserId, ClientId) => fetch('get', url`/admin/appClient/${UserId}/${ClientId}`)
export const AdminUpdate = (data) => fetch('put', url`/admin/appClient`, data)
export const List = () => fetch('get', url`/appClient`)
export const create = data => fetch('post', '/appClient', data)
export const update = data => fetch('put', '/appClient', data)
export const getSecret = id => fetch('post', '/appClient/secret', { ClientId: id })
export const Get = (ClientId) => fetch('get', url`/appClient/${ClientId}`)
export const deleteById = id => fetch('delete', url`/appClient/${id}`)
export const generateToken = (ClientId, ClientSecret) =>
  axios.post(window.config.CognitoUrlForToken, `grant_type=client_credentials&client_id=${ClientId}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': "Basic " + btoa(`${ClientId}:${ClientSecret}`),
    }
  }).then(res => { return res.data.access_token })
