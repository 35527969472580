// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import * as AppClientService from "../../services/appClient";
import * as ScopeService from "../../services/scopeService";
import {
  Button,
  Icon,
  Container,
  Form,
  Accordion,
  Dimmer,
  Loader,
  Grid,
  Popup,
} from "semantic-ui-react";
import moment from "moment";
import { secondsFromUnitTime } from "../../utils/timeCalculations";
import _ from "lodash";
import { fragments } from "services/get-fragments";

const defaultValues = {
  IdTokenValidity: 60,
  FollowValidity: true,
  RefreshTokenValidity: 30,
  AccessTokenValidity: 60,
  TokenValidityUnits: {
    IdToken: "minutes",
    AccessToken: "minutes",
    RefreshToken: "days",
  },
};

const options = [
  { key: "Y", text: "Years", value: "years" },
  { key: "M", text: "Months", value: "months" },
  { key: "D", text: "Days", value: "days" },
  { key: "H", text: "Hours", value: "hours" },
  { key: "I", text: "Minutes", value: "minutes" },
  { key: "S", text: "Seconds", value: "seconds" },
];

export default (p) => {
  const { setModalFormOpen, id } = p;
  const [data, setData] = useState(defaultValues);
  const [loading, setLoading] = useState(true);
  const [scopes, setScopes] = useState([]);

  const handleChangeParams = (event) => {
    data[event.target.name] = event.target.value;
    data.IdTokenValidity = parseInt(data.IdTokenValidity);
    data.RefreshTokenValidity = parseInt(data.RefreshTokenValidity);
    data.AccessTokenValidity = parseInt(data.AccessTokenValidity);
    // if (data.FollowValidity) {
    //   data= {...data, ...defaultValues}
    // }
    setData({ ...data });
  };

  const handleChangeValidityUnits = (event) => {
    const newValue = [];
    newValue[event.target.name] = event.target.value;
    data["TokenValidityUnits"] = { ...data["TokenValidityUnits"], ...newValue };
    setData({ ...data });
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      const tRefresh =
        data.RefreshTokenValidity *
        secondsFromUnitTime(data.TokenValidityUnits.RefreshToken);
      const tAccess =
        data.AccessTokenValidity *
        secondsFromUnitTime(data.TokenValidityUnits.AccessToken);
      const tId =
        data.IdTokenValidity *
        secondsFromUnitTime(data.TokenValidityUnits.IdToken);
      // Refresh Entre 60 Min y 3650 Dias
      if (
        tRefresh < 60 * secondsFromUnitTime("minutes") ||
        tRefresh > 3650 * secondsFromUnitTime("days")
      ) {
        throw Error("RefreshToken Fuera de Rango");
      }
      if (
        tAccess < 5 * secondsFromUnitTime("minutes") ||
        tAccess > 1 * secondsFromUnitTime("days")
      ) {
        throw Error("AccessToken Fuera de Rango");
      }
      if (
        tId < 5 * secondsFromUnitTime("minutes") ||
        tId > 1 * secondsFromUnitTime("days")
      ) {
        throw Error("IdToken Fuera de Rango");
      }
      if (tAccess > tRefresh) {
        throw Error("AccessToken No puede ser mayor a RefreshToken");
      }
      if (tId > tRefresh) {
        throw Error("IdToken No puede ser mayor a RefreshToken");
      }
      setLoading(true);
      await AppClientService.AdminUpdate(data).finally(() => setLoading(false));
      setModalFormOpen(false);
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  // Initial load
  useEffect(() => {
    (async () => {
      try {
        const scopes = await ScopeService.List();
        setScopes(scopes);
        const appClient = await AppClientService.AdminGet(
          id.UserId,
          id.ClientId
        );
        setData(appClient.Item);
      } catch (error) { }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Form onSubmit={handleCreate}>
        <Grid columns={2}>
          <Grid.Column>
            <Form.Field>
              <Form.Input
                label={fragments.AppClient.appName}
                defaultValue={data.ClientName}
                readOnly
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Form.Input
                label={fragments.AppClient.clientId}
                defaultValue={data.ClientId}
                readOnly
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Form.Input
                label={`${fragments.Profile.username}:`}
                defaultValue={data.UserEmail}
                readOnly
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Popup
                content={new Date(data.CreationDate).toString()}
                trigger={
                  <Form.Input
                    label={`${fragments.common.created}:`}
                    value={moment(new Date(data.CreationDate)).fromNow()}
                    readOnly
                  />
                }
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
        <p>
          <b>{fragments.AppClient.tokenValidityTracking}:</b>
        </p>
        <Form.Group>
          <Form.Field>
            <Form.Radio
              label={fragments.AppClient.tokenValidityFollow}
              value="1"
              checked={data.FollowValidity === true}
              onChange={() =>
                setData({ ...data, FollowValidity: true, ...defaultValues })
              }
            />
            <Form.Radio
              label={fragments.AppClient.tokenValiditySpecify}
              value="0"
              checked={data.FollowValidity === false}
              onChange={() => setData({ ...data, FollowValidity: false })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Accordion fluid styled>
            <Accordion.Title
              active={!data.FollowValidity}
              index={0}
            // onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              {fragments.AppClient.tokenValidity}
            </Accordion.Title>
            <Accordion.Content active={!data.FollowValidity}>
              <Form.Group inline>
                <b>{fragments.AppClient.refreshToken}: </b>
                <Form.Field>
                  <input
                    min="0"
                    name="RefreshTokenValidity"
                    value={data.RefreshTokenValidity}
                    type="number"
                    style={{ width: 100 }}
                    onChange={handleChangeParams}
                  />
                </Form.Field>
                <Form.Field>
                  <select
                    name="RefreshToken"
                    value={data.TokenValidityUnits.RefreshToken}
                    onChange={handleChangeValidityUnits}
                  >
                    {options.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                        defaultValue={data.TokenValidityUnits.RefreshToken}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </Form.Field>
                {fragments.AppClient.RefreshTokenHelpText}
              </Form.Group>
              <Form.Group inline>
                <b>{fragments.AppClient.accessToken}: </b>
                <Form.Field>
                  <input
                    min="0"
                    name="AccessTokenValidity"
                    value={data.AccessTokenValidity}
                    type="number"
                    style={{ width: 100 }}
                    onChange={handleChangeParams}
                  />
                </Form.Field>
                <Form.Field>
                  <select
                    name="AccessToken"
                    value={data.TokenValidityUnits.AccessToken}
                    onChange={handleChangeValidityUnits}
                  >
                    {options.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                        defaultValue={data.TokenValidityUnits.AccessToken}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </Form.Field>
                {fragments.AppClient.IdAndAccesTokenHelpText}
              </Form.Group>
              <Form.Group inline>
                <b>{fragments.AppClient.idToken}: </b>
                <Form.Field>
                  <input
                    min="0"
                    name="IdTokenValidity"
                    value={data.IdTokenValidity}
                    type="number"
                    style={{ width: 100 }}
                    onChange={handleChangeParams}
                  />
                </Form.Field>
                <Form.Field>
                  <select
                    name="IdToken"
                    value={data.TokenValidityUnits.IdToken}
                    onChange={handleChangeValidityUnits}
                  >
                    {options.map((option) => (
                      <option
                        key={option.key}
                        value={option.value}
                        defaultValue={data.TokenValidityUnits.IdToken}
                      >
                        {option.text}
                      </option>
                    ))}
                  </select>
                </Form.Field>
                {fragments.AppClient.IdAndAccesTokenHelpText}
              </Form.Group>
            </Accordion.Content>
          </Accordion>
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <p>
              <b>{fragments.AppClient.oauthScopes}:</b>
            </p>
            {scopes.map((scope) => (
              <Form.Checkbox
                key={scope.Scope}
                label={`${scope.Scope} - ${scope.Description} `}
                checked={_.includes(data.AllowedOAuthScopes, scope.Scope)}
              />
            ))}
          </Form.Field>
        </Form.Group>
        <Container style={{ paddingTop: "1em", textAlign: "right" }}>
          <Button type="submit" primary>
            <Icon name="save" /> Save
          </Button>
        </Container>
      </Form>
    </Container>
  );
};
