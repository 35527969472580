import React, { useState } from "react";
import { Auth } from "../services/AmplifyAuth";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import nav_logo from "../custom-content/nav-logo.svg";
import { fragments } from "services/get-fragments";

export default () => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await Auth.resendSignUp(username);
        alert(fragments.Session.codeResent);
        window.location = `/signUp/${username}`
      } catch (error) {
        alert(`${fragments.Session.errorSignUp}: ${error.message}`);
      }
      setLoading(false);
    })();
  };

  const handleChangeUser = (event) => {
    setUsername(event.target.value);
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh", backgroundColor: "#EEE", marginTop: 0 }}
      columns={2}
    >
      <Grid.Column
        verticalAlign="middle"
        style={{
          minHeight: "50vh",
          maxWidth: 200,
          backgroundColor: "gray",
          padding: 40,
        }}
      >
        <Image src={nav_logo} /> <h1>Welcome</h1>
      </Grid.Column>
      <Grid.Column
        textAlign="left"
        verticalAlign="middle"
        style={{
          minHeight: "50vh",
          maxWidth: 400,
          backgroundColor: "white",
          padding: 40,
        }}
      >
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        <Form onSubmit={handleSubmit}>
          <Header as="h1" textAlign="center">
            {fragments.Session.requestNewCode}
          </Header>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder={fragments.Session.email}
            name="user"
            type="email"
            value={username}
            onChange={handleChangeUser}
          />
          <br />
          <Button primary fluid>
            {fragments.Session.sendRegistrationCode}
          </Button>
          <br />
        </Form>
      </Grid.Column>
    </Grid>
  );
};
