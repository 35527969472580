import React, { useState, useEffect } from "react";
import { Auth } from "../services/AmplifyAuth";
import {
  Button,
  Form,
  Header,
  Dimmer,
  Loader,
  FormField,
} from "semantic-ui-react";
import QRCode from "qrcode.react";
import { fragments } from "../services/get-fragments"

export default (props) => {
  const { onVerifyTotpToken, cognitoUser, email } = props;
  const [qrCode, setQrCode] = useState(undefined);
  const [key, setKey] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [mfaCode, setMfaCode] = useState("");

  const handleChangeMFACode = (event) => {
    setMfaCode(event.target.value);
  };

  const pasteClipboard = async () => {
    const clipboardContent = await navigator.clipboard.readText();
    setMfaCode(clipboardContent);
  };

  const copyClipboard = async () => {
    await navigator.clipboard.writeText(key)
  };

  useEffect(() => {
    (async () => {
      try {
        const code = await Auth.setupTOTP(cognitoUser);
        setQrCode(
          "otpauth://totp/AWSCognito:" +
          email +
          "?secret=" +
          code +
          "&issuer=DevPortal"
        );
        setKey(code);
      } catch (error) {
        alert(`Error Getting Auth User ${error}`);
      }
      setLoading(false);
    })();
  }, [cognitoUser, email]);

  const verifyTotpToken = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await Auth.verifyTotpToken(cognitoUser, mfaCode);
        await Auth.setPreferredMFA(cognitoUser, "TOTP");
        alert(
          fragments.Session.totpSuccess
        );
        if (onVerifyTotpToken) {
          onVerifyTotpToken(mfaCode);
        }
      } catch (error) {
        console.log(error);
        alert(`${fragments.Session.totpErrorVerifying}: ${error.message}`);
      }
      setLoading(false);
    })();
  };

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Form onSubmit={verifyTotpToken}>
        <Header as="h1" textAlign="center">
          {fragments.Session.setupMFA}
        </Header>
        {qrCode ? (
          <p>
            {fragments.Session.totpSetupInstructions}
          </p>
        ) : (
          <p>
            {fragments.Session.totpSetupInstructionsNoQR}
          </p>
        )}
        {qrCode && (
          <p style={{ textAlign: "center" }}>
            <QRCode value={qrCode} />
          </p>
        )}
        {key && (
          <FormField>
            {fragments.Session.totpSetupInstructionsKey}:
            <Form.Input
              value={key}
              action={{
                icon: "copy",
                labelPosition: "right",
                content: fragments.common.copy,
                onClick: (event) => {
                  event.preventDefault();
                  copyClipboard();
                },
              }}
            />
          </FormField>
        )}
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={fragments.Session.code}
          name="mfa_code"
          type="number"
          value={mfaCode}
          onChange={handleChangeMFACode}
          action={{
            icon: "paste",
            labelPosition: "right",
            content: fragments.common.paste,
            onClick: (event) => {
              event.preventDefault();
              pasteClipboard();
            },
          }}
        />
        <Button primary fluid>
          {qrCode ? fragments.Session.confirmQrCode : fragments.Session.submit}
        </Button>
      </Form>
    </>
  );
};
