// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { Menu, Image, Dropdown, Icon } from "semantic-ui-react";
import navLogo from "../custom-content/nav-logo.png";

import {
  isAdmin,
  isAuthenticated,
  // isRegistered,
  logout,
  // getLoginRedirectUrl
} from "services/self";

// import { cognitoDomain, cognitoClientId } from '../services/api'

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

// components
import MenuLink from "components/MenuLink";
import { store } from "services/state";

// function getCognitoUrl (type) {
//   const redirectUri = getLoginRedirectUrl()
//   return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
// }

export const NavBar = observer(
  class NavBar extends React.Component {
    render() {
      const email = store.user && store.user.email;
      return (
        <Menu borderless attached style={{ flex: "0 0 auto", border: 0 }} stackable className='navBar'>
          <Image src={navLogo} style={{ width: 261 }} onClick={() => window.location = "/"} />
          {/* {fragments.Home.title} */}

          <MenuLink to="/getting-started">
            {fragments.GettingStarted.title}
          </MenuLink>
          {isAuthenticated() && (
            <MenuLink to="/explorer">{fragments.APIs.title}</MenuLink>
          )}
          {isAuthenticated() && !isAdmin() && (
            <MenuLink to="/AppClients">
              {fragments.AppClient.navBarTitle}
            </MenuLink>
          )}

          <Menu.Menu position="right">
            {isAuthenticated() ? (
              <>
                {isAdmin() && <MenuLink to="/admin/apis">{fragments.Session.adminPanel}</MenuLink>}
                {/* {isRegistered() && (
                  <MenuLink to="/dashboard">My Dashboard</MenuLink>
                )} */}
                <Dropdown item text={email}>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item icon="edit" text="Edit Profile" onClick={()=> window.location='/profile'} /> */}
                    <MenuLink to="/profile">
                      <Icon name="user circle" /> {fragments.Session.accountSettings}
                    </MenuLink>
                    <Dropdown.Item
                      icon="power off"
                      text={fragments.Session.signOut}
                      onClick={logout}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <MenuLink to="/explorer">{fragments.Session.signIn}</MenuLink>
                {window.config.AccountRegistrationMode === "open" && (
                  <MenuLink to="/signUp">{fragments.Session.register}</MenuLink>
                )}
              </>
            )}
          </Menu.Menu>
        </Menu>
      );
    }
  }
);

export default NavBar;
