// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import * as ParamsService from "../../services/paramsService";

import {
  Button,
  Icon,
  Container,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { secondsFromUnitTime } from "../../utils/timeCalculations";
import { fragments } from 'services/get-fragments'

const defaultValues = {
  IdTokenValidity: 60,
  RefreshTokenValidity: 30,
  AccessTokenValidity: 60,
  TokenValidityUnits: {
    IdToken: "minutes",
    AccessToken: "minutes",
    RefreshToken: "days",
  },
};

const options = [
  { key: "Y", text: "Years", value: "years" },
  { key: "M", text: "Months", value: "months" },
  { key: "D", text: "Days", value: "days" },
  { key: "H", text: "Hours", value: "hours" },
  { key: "I", text: "Minutes", value: "minutes" },
  { key: "S", text: "Seconds", value: "seconds" },
];

export default (p) => {
  const { setModalFormOpen } = p;

  const [data, setData] = useState(defaultValues);
  const [saveOptions, setSaveOptions] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleChangeParams = (event) => {
    data[event.target.name] = event.target.value;
    data.IdTokenValidity = parseInt(data.IdTokenValidity);
    data.RefreshTokenValidity = parseInt(data.RefreshTokenValidity);
    data.AccessTokenValidity = parseInt(data.AccessTokenValidity);
    // if (data.FollowValidity) {
    //   data= {...data, ...defaultValues}
    // }
    setData({ ...data });
  };

  const handleCreate = async () => {
    try {
      const tRefresh =
        data.RefreshTokenValidity *
        secondsFromUnitTime(data.TokenValidityUnits.RefreshToken);
      const tAccess =
        data.AccessTokenValidity *
        secondsFromUnitTime(data.TokenValidityUnits.AccessToken);
      const tId =
        data.IdTokenValidity *
        secondsFromUnitTime(data.TokenValidityUnits.IdToken);
      // Refresh Entre 60 Min y 3650 Dias
      if (
        tRefresh < 60 * secondsFromUnitTime("minutes") ||
        tRefresh > 3650 * secondsFromUnitTime("days")
      ) {
        throw Error("RefreshToken out of range");
      }
      if (
        tAccess < 5 * secondsFromUnitTime("minutes") ||
        tAccess > 1 * secondsFromUnitTime("days")
      ) {
        throw Error("AccessToken out of range");
      }
      if (
        tId < 5 * secondsFromUnitTime("minutes") ||
        tId > 1 * secondsFromUnitTime("days")
      ) {
        throw Error("IdToken out of range");
      }
      if (tAccess > tRefresh) {
        throw Error("AccessToken cannot be greater than RefreshToken");
      }
      if (tId > tRefresh) {
        throw Error("IdToken cannot be greater than RefreshToken");
      }
      setLoading(true);
      await ParamsService.Update({
        params: data,
        saveOptions: saveOptions,
      }).finally(() => setLoading(false));
      setModalFormOpen(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleChangeValidityUnits = (event) => {
    const newValue = [];
    newValue[event.target.name] = event.target.value;
    data["TokenValidityUnits"] = { ...data["TokenValidityUnits"], ...newValue };
    setData({ ...data });
  };

  // Initial load
  useEffect(() => {
    ParamsService.List()
      .then((out) => {
        if (out.Item) {
          setData(out.Item);
        }
      })
      .catch(console.log("No Params"))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Form onSubmit={handleCreate}>
        <p>
        <b>{fragments.AppClient.refreshToken}: </b>
        </p>
        <Form.Group inline>
          <Form.Field>
            <input
              min="0"
              name="RefreshTokenValidity"
              value={data.RefreshTokenValidity}
              type="number"
              style={{ width: 100 }}
              onChange={handleChangeParams}
            />
          </Form.Field>
          <Form.Field>
            <select
              name="RefreshToken"
              value={data.TokenValidityUnits.RefreshToken}
              onChange={handleChangeValidityUnits}
            >
              {options.map((option) => (
                <option
                  key={option.key}
                  value={option.value}
                  defaultValue={data.TokenValidityUnits.RefreshToken}
                >
                  {option.text}
                </option>
              ))}
            </select>
          </Form.Field>
          {fragments.AppClient.RefreshTokenHelpText}
        </Form.Group>
        <p>
        <b>{fragments.AppClient.accessToken}: </b>
        </p>
        <Form.Group inline>
          <Form.Field>
            <input
              min="0"
              name="AccessTokenValidity"
              value={data.AccessTokenValidity}
              type="number"
              style={{ width: 100 }}
              onChange={handleChangeParams}
            />
          </Form.Field>
          <Form.Field>
            <select
              name="AccessToken"
              value={data.TokenValidityUnits.AccessToken}
              onChange={handleChangeValidityUnits}
            >
              {options.map((option) => (
                <option
                  key={option.key}
                  value={option.value}
                  defaultValue={data.TokenValidityUnits.AccessToken}
                >
                  {option.text}
                </option>
              ))}
            </select>
          </Form.Field>
          {fragments.AppClient.IdAndAccesTokenHelpText}
        </Form.Group>
        <p>
        <b>{fragments.AppClient.idToken}: </b>
        </p>
        <Form.Group inline>
          <Form.Field>
            <input
              min="0"
              name="IdTokenValidity"
              value={data.IdTokenValidity}
              type="number"
              style={{ width: 100 }}
              onChange={handleChangeParams}
            />
          </Form.Field>
          <Form.Field>
            <select
              name="IdToken"
              value={data.TokenValidityUnits.IdToken}
              onChange={handleChangeValidityUnits}
            >
              {options.map((option) => (
                <option
                  key={option.key}
                  value={option.value}
                  defaultValue={data.TokenValidityUnits.IdToken}
                >
                  {option.text}
                </option>
              ))}
            </select>
          </Form.Field>
          {fragments.AppClient.IdAndAccesTokenHelpText}
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Form.Radio
              label={fragments.AppClient.saveForNewApp}
              value="1"
              checked={saveOptions === 1}
              onChange={() => setSaveOptions(1)}
            />
            <Form.Radio
              label={fragments.AppClient.saveForGlobal}
              value="2"
              checked={saveOptions === 2}
              onChange={() => setSaveOptions(2)}
            />
            <Form.Radio
              label={fragments.AppClient.saveForAll}
              value="3"
              checked={saveOptions === 3}
              onChange={() => setSaveOptions(3)}
            />
          </Form.Field>
        </Form.Group>
        <hr />
        <Container style={{ padding: "1em", textAlign: "right" }}>
          <Button type="submit" primary>
            <Icon name="save" /> Save
          </Button>
        </Container>
      </Form>
    </Container>
  );
};
