import React, { useEffect, useState } from "react";
import * as apiTagService from "services/apiTag";
import { Grid, List, Segment, Form, Image, Header, Button, Icon, Placeholder, Dimmer, Loader } from 'semantic-ui-react'
import { apiGatewayClientWithCredentials } from 'services/api'
import { Link } from "react-router-dom";
import _ from 'lodash'
import { fragments } from "services/get-fragments";

export default () => {
  const [apiTags, setApiTag] = useState([]);
  const [apis, setApis] = useState([]);
  const [selectedApis, setSelectedApis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoriesSelected, setCategoriesSelected] = useState([])

  const handleChangeScopes = async (event, data) => {
    var s = []
    if (data.checked) {
      s = [...categoriesSelected, data.name]
      setCategoriesSelected([...categoriesSelected, data.name]);
    } else {
      _.remove(categoriesSelected, (e) => {
        return e === data.name;
      });
      setCategoriesSelected([...categoriesSelected]);
      s = [...categoriesSelected]
    }

    const sApis = []
    for (const key in apiTags) {
      if (!_.includes(s, key)) {
        continue
      }
      for (let i = 0; i < apiTags[key].length; i++) {
        for (let j = 0; j < apis.length; j++) {
          if (apis[j].id === apiTags[key][i].id) {
            sApis.push(apis[j]);
          }
        }
      }
    }
    if (sApis.length > 0) {
      setSelectedApis(sApis)
    } else {
      setSelectedApis(apis)
    }
  };

  const getLogo = (apiList) => {
    const promesas = []
    var i = 0
    apiList.forEach(api => {
      if (!api.logo) {
        const key = api.apiStage == null ? api.id : `${api.apiId}_${api.apiStage}`
        const specificLogo = `/custom-content/api-logos/${key}.png`

        // fetch automatically follows redirects; setting redirect to `manual` prevents this
        // we need to prevent it so that we can accurately determine if the image exists

        promesas[i] = window.fetch(specificLogo, { headers: { Accept: 'image/png' }, redirect: 'manual' }).then(response => {
          if (response.ok) { api.logo = specificLogo } else api.logo = '/custom-content/api-logos/default.png'
        })
        i++

      }
    })
    console.log('AfterFetch')
    return Promise.all(promesas)
  }

  // Initial load
  useEffect(() => {
    setLoading(true);
    apiGatewayClientWithCredentials()
      .then(app => app.get('/catalog', {}, {}, {}))
      .then(res => {
        apiTagService.Get().then(async (related) => {
          setApiTag(related)
          await getLogo(res.data.generic)
          setApis(res.data.generic)
          setSelectedApis(res.data.generic)
        })
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      columns={2}
      padded
      centered
    >
      <Grid.Column width={"4"}>
        <Segment className="ExplorerCategories" style={{ minHeight: 400 }}>
          <p><b>{fragments.categories.categories}:</b></p>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
          {!loading &&
            <List>
              {Object.keys(apiTags).map((key, value) => (
                <List.Item key={key} className="listCategories">
                  <Form.Checkbox
                    key={key}
                    name={key}
                    label={`${key}`}
                    // checked={_.includes(appScopes, scope.Scope)}
                    onChange={handleChangeScopes}
                  />
                </List.Item>
              ))}
            </List>}
        </Segment>
      </Grid.Column>
      <Grid.Column width={"8"}>
        <Grid>
          {(loading || selectedApis.length === 0) && [1, 2, 3, 4].map(i => (
            <Grid.Row key={i}>
              <Segment className="ExplorerCategories">
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              </Segment>
            </Grid.Row>
          ))}
          {!loading && selectedApis.map(api => (
            <Grid.Row key={api.id + api.apiStage}>
              <Segment className="ExplorerCategories">
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: '0 0 auto', marginRight: '20px' }}>
                    <Image size='tiny' src={api.logo || '/custom-content/api-logos/default.png'} />
                  </div>
                  <div>
                    <Header as='h2'>{api.swagger.info.title}</Header>
                    <div style={{ display: 'flex', paddingBottom: '1em' }}>
                      <div style={{ marginRight: '20px' }}>
                        <p>{api.swagger.info.description}</p>
                        <p><Link to={`/apis/${api.id}/${api.apiStage}`}><Button primary>{fragments.categories.seeFullDoc} <Icon name="angle double right" /></Button></Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </Segment>
            </Grid.Row>
          ))}
        </Grid>
      </Grid.Column>
    </Grid>
  )
}