// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

// mobx
import * as AppClientService from "../../services/appClient";
import {
  Button,
  Icon,
  Confirm,
  Table,
  Container,
  Modal,
  Popup,
  Header,
  Placeholder,
} from "semantic-ui-react";
import AppClientParametersForm from "./AppClientParametersForm";
import AppClientForm from "./AppClientForm";

// fragments
import { fragments } from "services/get-fragments";

export default () => {
  const [appClients, setAppClients] = useState([]);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [key, setKey] = useState({});
  const [loading, setLoading] = useState(true);

  const confirmDelete = async (UserId, ClientId) => {
    setModalDeleteOpen(true);
    setKey({ UserId: UserId, ClientId: ClientId });
  };

  const openEditForm = async (UserId, ClientId) => {
    setModalEditOpen(true);
    setKey({ UserId: UserId, ClientId: ClientId });
  };

  const handleDelete = async () => {
    setModalDeleteOpen(false);
    try {
      await AppClientService.AdminDelete(key);
      const appClientToDelete = [...appClients];
      _.remove(appClientToDelete, function (n) {
        return n.ClientId === key.ClientId;
      });
      setAppClients(appClientToDelete);
    } catch (error) {
      alert(error.message);
    }
  };

  // Initial load
  useEffect(() => {
    setLoading(true);
    AppClientService.AdminList()
      .then((out) => {
        setAppClients(out.Items);
      })
      .finally(() => setLoading(false));
  }, [modalEditOpen]);

  return (
    <>
      <Confirm
        open={modalDeleteOpen}
        header={fragments.AppClient.delete}
        onCancel={() => setModalDeleteOpen(false)}
        onConfirm={handleDelete}
        cancelButton={fragments.common.cancel}
        confirmButton={fragments.common.delete}
        content={fragments.AppClient.confirmDelete}
      />
      <Container fluid style={{ padding: "2em" }}>
        <Header as="h1">{fragments.AppClient.title}</Header>
        <Modal
          open={modalEditOpen}
          onClose={() => setModalEditOpen(false)}
          onOpen={() => setModalEditOpen(true)}
          onCancel={() => setModalEditOpen(false)}
        >
          <Modal.Header>{fragments.AppClient.edit}</Modal.Header>
          <AppClientForm setModalFormOpen={setModalEditOpen} id={key} />
        </Modal>
        <div style={{ float: "right", marginBottom: "1rem" }}>
          <Button.Group>
            <Modal
              open={modalFormOpen}
              onClose={() => setModalFormOpen(false)}
              onOpen={() => setModalFormOpen(true)}
              onCancel={() => setModalFormOpen(false)}
              trigger={
                <Button primary>{fragments.AppClient.globalTokenValiddity}</Button>
              }
            >
              <Modal.Header>
                {fragments.AppClient.globalTokenValiddity}
              </Modal.Header>
              <AppClientParametersForm setModalFormOpen={setModalFormOpen} />
            </Modal>
          </Button.Group>
        </div>
        <div style={{ width: "100%" }}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.AppClient.username}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.AppClient.appName}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.AppClient.clientId}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.AppClient.followValidity}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.common.created}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.common.action}

                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  {[1, 2, 3, 4, 5, 6].map((n) => (
                    <Table.Cell key={n}>
                      <Placeholder fluid>&nbsp;</Placeholder>
                    </Table.Cell>
                  ))}
                </Table.Row>
              ) : appClients.length > 0 ? (
                appClients.map((app) => (
                  <Table.Row key={app.ClientId}>
                    <Table.Cell>{app.UserEmail}</Table.Cell>
                    <Table.Cell>{app.ClientName}</Table.Cell>
                    <Table.Cell>
                      <CopyToClipboard text={app.ClientId}>
                        <Button>
                          <Icon name="copy" /> {app.ClientId}
                        </Button>
                      </CopyToClipboard>
                    </Table.Cell>
                    <Table.Cell>{app.FollowValidity ? <Icon name="checkmark" /> : ""}</Table.Cell>
                    <Table.Cell>
                      <Popup
                        content={(new Date(app.CreationDate)).toString()}
                        trigger={
                          <div>{moment(app.CreationDate).fromNow()}</div>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell style={{ whiteSpace: "nowrap" }}>
                      <Button
                        onClick={() => openEditForm(app.UserId, app.ClientId)}
                      >
                        <Icon name="edit" /> {fragments.common.edit}
                      </Button>
                      <Button
                        onClick={() => confirmDelete(app.UserId, app.ClientId)}
                      >
                        <Icon name="trash alternate" /> {fragments.common.delete}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="6">
                    {fragments.appClient.noAppClients}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </Container>
    </>
  );
};
