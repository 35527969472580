import React, { useState } from "react";
import { Auth } from "../services/AmplifyAuth";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { loginClient } from "../services/self";
import { fragments } from "../services/get-fragments"
import { Link } from "react-router-dom";
import TOTPSetupFortm from "components/TOTPSetupFortm";

export default () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cognitoUser, setCognitoUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showSignInForm, setShowSignInForm] = useState(true);
  const [showMfaSetupForm, setShowMfaSetupForm] = useState(false);
  const [showSignInConfirmForm, setShowSiginConfirmForm] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [mfaCode, setMfaCode] = useState("");

  const handleSubmitSignIn = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        const user = await Auth.signIn(username, password);
        setCognitoUser(user);
        if (!user.challengeName || user.challengeName === "MFA_SETUP") {
          setShowSignInForm(false);
          setShowMfaSetupForm(true);
          setShowSiginConfirmForm(false);
          setShowChangePasswordForm(false);
        } else if (
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
        ) {
          setShowSignInForm(false);
          setShowMfaSetupForm(false);
          setShowSiginConfirmForm(true);
          setShowChangePasswordForm(false);
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setShowSignInForm(false);
          setShowMfaSetupForm(false);
          setShowSiginConfirmForm(false);
          setShowChangePasswordForm(true);
        }
      } catch (error) {
        alert(`${fragments.Session.errorSignIn}: ${error.code} ${error.message}`);
      }
      setLoading(false);
    })();
  };

  const handleSignInConfirm = (code) => {
    // event.preventDefault();
    if (!code) {
      code = mfaCode;
    }
    (async () => {
      setLoading(true);
      try {
        await Auth.confirmSignIn(
          cognitoUser, // Return object from Auth.signIn()
          code, // Confirmation code
          "SOFTWARE_TOKEN_MFA" // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );
        loginClient(cognitoUser.signInUserSession.idToken.jwtToken);
        window.location.reload();
      } catch (error) {
        alert(`${fragments.Session.errorConfirmSingIn}: ${error.message}`);
        setLoading(false);
      }
    })();
  };

  const handleSubmitChangePassword = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await Auth.completeNewPassword(
          cognitoUser, // Return object from Auth.signIn()
          password // Confirmation code
        );
        alert(fragments.Session.passwordChanged);
        setShowSignInForm(true);
        setShowMfaSetupForm(false);
        setShowSiginConfirmForm(false);
        setShowChangePasswordForm(false);

      } catch (error) {
        console.log(error);
        alert(`${fragments.Session.errorConfirmSingIn}: ${error.message}`);
      }
      setLoading(false);
    })();
  };

  const handleChangeUser = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeMFACode = (event) => {
    setMfaCode(event.target.value);
  };

  const pasteClipboard = async () => {
    const clipboardContent = await navigator.clipboard.readText();
    setMfaCode(clipboardContent);
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh", backgroundColor: "#dce1e9", marginTop: 0 }}
      columns={2}
    >
      <Grid.Column
        verticalAlign="middle"
        style={{
          minHeight: "50vh",
          maxWidth: 400,
          backgroundColor: "white",
          padding: 40,
          borderRadius: 6, boxShadow: "0px 3px 9px rgba(0,0,0,0.17)"
        }}
      >
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        {showSignInForm && (
          <Form onSubmit={handleSubmitSignIn}>
            <Header as="h1" textAlign="center">
              {fragments.Session.signIn}
            </Header>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={fragments.Session.emailAddress}
              name="user"
              type="email"
              value={username}
              onChange={handleChangeUser}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={fragments.Session.password}
              name="password"
              type="password"
              value={password}
              onChange={handleChangePassword}
            />
            <Button fluid primary>
              {fragments.Session.enter}
            </Button>
            <Message>
              {window.config.AccountRegistrationMode === 'open' && (
                <>
                  {fragments.Session.newToUs}? <Link to="/signup">{fragments.Session.signUo}</Link>
                  <br />
                </>
              )}
              {fragments.Session.forgotPassword}? <Link to="/forgotPassword">{fragments.Session.recover}</Link>
            </Message>
          </Form>
        )}
        {showMfaSetupForm && (
          <TOTPSetupFortm
            cognitoUser={cognitoUser}
            email={username}
            onVerifyTotpToken={() => {
              setShowSignInForm(true);
              setShowMfaSetupForm(false);
              setShowSiginConfirmForm(false);
            }}
          />
        )}
        {showSignInConfirmForm && (
          <Form onSubmit={() => handleSignInConfirm()}>
            <Header as="h1" textAlign="center">
              {fragments.Session.codeNeeded}
            </Header>
            <p>
              {fragments.Session.mfaCodeSetup}
            </p>
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={fragments.Session.code}
              name="mfa_code"
              type="number"
              value={mfaCode}
              onChange={handleChangeMFACode}
              action={{
                icon: "paste",
                labelPosition: "right",
                content: fragments.Session.paste,
                onClick: (event) => {
                  event.preventDefault();
                  pasteClipboard();
                },
              }}
            />
            <p>
              {fragments.Session.mfaProblems}
            </p>
            <Button fluid primary>
              {fragments.Session.enter}
            </Button>
          </Form>
        )}
        {showChangePasswordForm && (
          <Form onSubmit={handleSubmitChangePassword}>
            <Header as="h1" textAlign="center">
              {fragments.Session.newPasswordRequired}
            </Header>
            <Message>{fragments.Session.user}: {username}</Message>
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={fragments.Session.password}
              name="password"
              type="password"
              value={password}
              onChange={handleChangePassword}
            />
            <Button fluid primary>
              {fragments.Session.setNewPassword}
            </Button>
          </Form>
        )}
      </Grid.Column>
    </Grid>
  );
};
