import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api'

// const getAccountsWithFilter = async filter => {
//   const apiGatewayClient = await getApiGatewayClient()
//   const response = await apiGatewayClient.get(
//     '/appClient',
//     undefined,
//     undefined,
//     { queryParams: { filter } }
//   )
//   return response.data.accounts
// }

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const List = () => fetch('get', url`/scope`)