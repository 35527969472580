import { apiGatewayClientWithCredentials as getApiGatewayClient } from 'services/api'

const url = ([first, ...rest], ...parts) =>
  first + parts.map((p, i) => encodeURIComponent(p) + rest[i]).join('')

const fetch = (method, target, params = {}) =>
  getApiGatewayClient()
    .then(apiGatewayClient => apiGatewayClient[method](target, {}, params, {}))
    .then(response => response.data)
    .catch(error => { throw error && (error.data || error) })

export const List = () => fetch('get', url`/params`)
export const Update = params => fetch('put', '/admin/params', params)