// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

import { isAdmin } from 'services/self'

import { observer } from 'mobx-react'

// import { Link } from 'react-router-dom'
import { Menu, Icon } from 'semantic-ui-react'
import Sidebar from 'components/Sidebar/Sidebar'
import SidebarHeader from 'components/Sidebar/SidebarHeader'
import MenuLink from 'components/MenuLink'
import { fragments } from 'services/get-fragments'

export const SideNav = observer(() => isAdmin() && (
  <Sidebar className="Sidebar" style={{
    width: '260px',
  }}>
    <SidebarHeader>{fragments.Session.adminPanel}:</SidebarHeader>
    <MenuLink to='/admin/apis'><Icon name="microchip" />APIs</MenuLink>
    <MenuLink>
      {fragments.Session.accounts}
      <Menu.Menu>
        <MenuLink to='/admin/accounts'><Icon name="user" />{fragments.Session.users}</MenuLink>
        <MenuLink to='/admin/accounts/admins'><Icon name="user md" />{fragments.Session.admins}</MenuLink>
        <MenuLink to='/admin/accounts/invites'><Icon name="address card" />{fragments.Session.invited}</MenuLink>
        <MenuLink to='/admin/groups'><Icon name="group" />{fragments.cognitoGroup.title}</MenuLink>
        {/* <MenuLink to='/admin/accounts/requests'>Requests</MenuLink> */}
      </Menu.Menu>
    </MenuLink>
    <MenuLink to='/admin/tags'><Icon name="tag" />{fragments.categories.categories}</MenuLink>
    <MenuLink to='/admin/appClient'><Icon name="plug" />{fragments.AppClient.title}</MenuLink>
  </Sidebar>
))
