// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import * as queryString from 'query-string'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import Home from 'pages/Home'
import GettingStarted from 'pages/GettingStarted'
import AppClient from 'pages/AppClient'
// import Dashboard from 'pages/Dashboard'
import Apis from 'pages/Apis'
import LoginForm from 'pages/SignIn'
import ForgotPassword from 'pages/ForgotPassword'
import SignUp from 'pages/SignUp'
import Profile from 'pages/Profile'
import CodeRequest from 'pages/CodeRequest'
import { Admin } from 'pages/Admin'

// components
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/Modal'
import NavBar from 'components/NavBar'
import Feedback from './components/Feedback'
import ApiSearch from './components/ApiSearch'

import { isAdmin, isRegistered, init, logout } from 'services/self'
import './index.css'
import { Segment } from 'semantic-ui-react'
import Explorer from 'pages/Explorer'

loadFragments()

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = window.config.feedbackEnabled

export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isRegistered() ? <Component {...props} /> : <LoginForm />
    }
  />
)

export const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
)

// To shut up a dev warning
const HomeWrap = (props) => <Home {...props} />
const GettingStartedWrap = (props) => <GettingStarted {...props} />
const AppClientWrap = (props) => <AppClient {...props} />
// const DashboardWrap = (props) => <Dashboard {...props} />

class App extends React.Component {
  constructor() {
    super()
    init()

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }
  }

  render() {
    return (
      <BrowserRouter>
        <>
          <NavBar />
          <GlobalModal />
          <Switch>
            <Route exact path="/" component={HomeWrap} />
            <Route
              exact
              path="/index.html"
              component={() => {
                const { action } = queryString.parse(window.location.search)
                /* if (action === 'login') {
                  login()
                } else */
                if (action === 'logout') {
                  logout()
                }
                return <Redirect to="/" />
              }}
            />
            <Route path="/getting-started" component={GettingStartedWrap} />
            <RegisteredRoute path="/AppClients" component={AppClientWrap} />
            {/* <RegisteredRoute path="/dashboard" component={DashboardWrap} /> */}
            <RegisteredRoute path="/profile" component={Profile} />
            <AdminRoute path="/admin" component={Admin} />
            <RegisteredRoute exact path="/explorer" component={Explorer} />
            <RegisteredRoute exact path="/apis" component={Apis} />
            <RegisteredRoute exact path="/apis/search" component={ApiSearch} />
            <RegisteredRoute exact path="/apis/:apiId" component={Apis} />
            <RegisteredRoute path="/apis/:apiId/:stage" component={Apis} />
            {/* <Route path='/login' render={() => { login(); return <Redirect to='/' /> }} /> */}
            {window.config.AccountRegistrationMode === 'open' && (
              <>
                <Route path="/signUp/:email" component={SignUp} />
                <Route path="/signUp" component={SignUp} />
              </>
            )}
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/codeRequest" component={CodeRequest} />
            <Route
              path="/logout"
              render={() => {
                logout()
                return <Redirect to="/" />
              }}
            />
            <Route component={() => <h2 style={{ textAlign: 'center' }}>Page not found</h2>} />
          </Switch>
          {feedbackEnabled && <Feedback />}
          <AlertPopup />
          <Segment textAlign='center' style={{ margin: 0 }}>Derechos Reservados - Banesco {(new Date()).getFullYear()}</Segment>
        </>
      </BrowserRouter >
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
