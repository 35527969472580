import React, { useCallback, useEffect, useState } from "react";
import { Button, Container, Header, Message, Modal } from "semantic-ui-react";

import * as MessageList from "components/MessageList";
import * as AccountService from "services/accounts";
import * as AccountsTable from "components/Admin/Accounts/AccountsTable";
import * as AccountsTableColumns from "components/Admin/Accounts/AccountsTableColumns";
import { store } from "services/state";
import _ from "lodash";
import { fragments } from "services/get-fragments";

const RegisteredAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [promoteModalOpen, setPromoteModalOpen] = useState(false);
  const [resetMFAModalOpen, setResetMFAModalOpen] = useState(false);
  const [messages, sendMessage] = MessageList.useMessages();

  const refreshAccounts = async () => {
    const [all, admins] = await Promise.all([
      AccountService.fetchRegisteredAccounts(),
      AccountService.fetchAdminAccounts(),
    ]);

    const allMap = {};

    all.forEach((user) => {
      allMap[user.UserId] = user;
    });
    admins.forEach((admin) => {
      admin.IsAdmin = true;
      if (admin.EmailAddress === store.user.email) {
        admin.EmailAddress += ` (${fragments.common.you})`;
      }
      allMap[admin.UserId] = admin;
    });

    return setAccounts(Object.values(allMap));
  };

  const isYou = (user) => {
    return _.get(store, "user.email") + ` (${fragments.common.you})` === user.EmailAddress;
  };

  // Initial load
  useEffect(() => {
    refreshAccounts().finally(() => setLoading(false));
  }, []);

  const onSelectAccount = useCallback(
    (account) => setSelectedAccount(account),
    [setSelectedAccount]
  );

  const onConfirmDelete = useCallback(async () => {
    setLoading(true);
    setDeleteModalOpen(false);
    try {
      await AccountService.deleteAccountByUserId(selectedAccount.UserId);
      sendMessage((dismiss) => (
        <DeleteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ));
      await refreshAccounts();
    } catch (error) {
      sendMessage((dismiss) => (
        <DeleteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ));
    } finally {
      setLoading(false);
    }
  }, [sendMessage, selectedAccount]);

  const onConfirmPromote = useCallback(async () => {
    setLoading(true);
    setPromoteModalOpen(false);
    try {
      await AccountService.promoteAccountByUserId(selectedAccount.UserId);
      sendMessage((dismiss) => (
        <PromoteSuccessMessage account={selectedAccount} dismiss={dismiss} />
      ));
      await refreshAccounts();
    } catch (error) {
      sendMessage((dismiss) => (
        <PromoteFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ));
    } finally {
      setLoading(false);
    }
  }, [sendMessage, selectedAccount]);

  const onResetMFA = useCallback(async () => {
    setLoading(true);
    setResetMFAModalOpen(false);
    try {
      await AccountService.resetMFAByUserId(selectedAccount.UserId);
      sendMessage((dismiss) => (
        <ResetMFASuccessMessage account={selectedAccount} dismiss={dismiss} />
      ));
    } catch (error) {
      sendMessage((dismiss) => (
        <ResetMFAFailureMessage
          account={selectedAccount}
          dismiss={dismiss}
          errorMessage={error.message}
        />
      ));
    } finally {
      setLoading(false);
    }
  }, [sendMessage, selectedAccount]);

  return (
    <Container fluid style={{ padding: "2em" }}>
      <Header as="h1">{fragments.Session.registeredAccounts}</Header>
      <MessageList.MessageList messages={messages} />
      <AccountsTable.AccountsTable
        accounts={accounts}
        columns={[
          AccountsTableColumns.EmailAddress,
          AccountsTableColumns.IsAdmin,
          AccountsTableColumns.DateRegistered,
          AccountsTableColumns.RegistrationMethod,
          AccountsTableColumns.ApiKeyId,
        ]}
        loading={loading}
        selectedAccount={selectedAccount}
        onSelectAccount={onSelectAccount}
      >
        <TableActions
          canDelete={!loading && selectedAccount && !isYou(selectedAccount)}
          onClickDelete={() => setDeleteModalOpen(true)}
          canPromote={!loading && selectedAccount}
          isAdmin={selectedAccount && selectedAccount.IsAdmin}
          onClickPromote={() => setPromoteModalOpen(true)}
          onClickResetMFA={() => setResetMFAModalOpen(true)}
        />
      </AccountsTable.AccountsTable>
      <DeleteAccountModal
        account={selectedAccount}
        onConfirm={onConfirmDelete}
        open={deleteModalOpen}
        isAdmin={selectedAccount && selectedAccount.IsAdmin}
        onClose={() => setDeleteModalOpen(false)}
      />
      <PromoteAccountModal
        account={selectedAccount}
        onConfirm={onConfirmPromote}
        open={promoteModalOpen}
        onClose={() => setPromoteModalOpen(false)}
      />
      <ResetMFAAccountModal
        account={selectedAccount}
        onConfirm={onResetMFA}
        open={resetMFAModalOpen}
        onClose={() => setResetMFAModalOpen(false)}
      />
    </Container>
  );
};
export default RegisteredAccounts;

const TableActions = React.memo(
  ({
    canDelete,
    onClickDelete,
    canPromote,
    onClickPromote,
    onClickResetMFA,
    isAdmin,
  }) => (
    <Button.Group>
      <Button primary content={fragments.common.delete} disabled={!canDelete} onClick={onClickDelete} />
      <Button
        primary
        content={fragments.Session.promoteToAdmin}
        disabled={!canPromote || isAdmin}
        onClick={onClickPromote}
      />
      <Button
        primary
        content={fragments.Session.resetMFA}
        disabled={!canDelete}
        onClick={onClickResetMFA}
      />
    </Button.Group>
  )
);

const DeleteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose, isAdmin }) =>
    account && (
      <Modal size="small" open={open} onClose={onClose}>
        <Modal.Header>{fragments.Session.deleteAccount}</Modal.Header>
        <Modal.Content>
          {isAdmin && (
            <Message negative>
              <Message.Header>{fragments.Session.warningAdmin}</Message.Header>
              <p>
                <strong>
                  {fragments.Session.warningAdminNote}
                </strong>
              </p>
            </Message>
          )}
          <p>{`${fragments.Session.deleteQuestion1}
            <strong>${account.EmailAddress}</strong>, ${fragments.Session.deleteQuestion2}`}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>{fragments.common.cancel}</Button>
          <Button negative onClick={onConfirm}>
            {fragments.common.delete}
          </Button>
        </Modal.Actions>
      </Modal>
    )
);

const PromoteAccountModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size="small" open={open} onClose={onClose}>
        <Modal.Header>{fragments.Session.confirPromotion}</Modal.Header>
        <Modal.Content>
          <p>{`${fragments.Session.promoteQuestion1} `}<strong>{account.EmailAddress}</strong>{` ${fragments.Session.promoteQuestion2} ${fragments.Session.promoteQuestionNote}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>{fragments.common.cancel}</Button>
          <Button negative onClick={onConfirm}>
            {fragments.Session.promote}
          </Button>
        </Modal.Actions>
      </Modal>
    )
);

const ResetMFAAccountModal = React.memo(
  ({ account, onConfirm, open, onClose }) =>
    account && (
      <Modal size="small" open={open} onClose={onClose}>
        <Modal.Header>{fragments.Session.resetMFA}</Modal.Header>
        <Modal.Content>
          <p>{fragments.Session.resetMfaConfirm}
            <strong>{account.EmailAddress}</strong> ?
          </p>
          <p>{fragments.Session.resetMfaNote}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>{fragments.common.cancel}</Button>
          <Button negative onClick={onConfirm}>
            {fragments.common.reset}
          </Button>
        </Modal.Actions>
      </Modal>
    )
);

const DeleteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      {fragments.Session.deleteAccount} <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
));

const DeleteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          {fragments.Session.failedDeleteAccount} <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
);

const PromoteSuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      {fragments.Session.promotedAccount} <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
));

const PromoteFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          {fragments.Session.failedPromoteAccount} <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
);

const ResetMFASuccessMessage = React.memo(({ account, dismiss }) => (
  <Message onDismiss={dismiss} positive>
    <Message.Content>
      {fragments.Session.mfaReseted} <strong>{account.EmailAddress}</strong>.
    </Message.Content>
  </Message>
));

const ResetMFAFailureMessage = React.memo(
  ({ account, errorMessage, dismiss }) => (
    <Message onDismiss={dismiss} negative>
      <Message.Content>
        <p>
          {fragments.Session.mfaResetedFailed} <strong>{account.EmailAddress}</strong>.
        </p>
        {errorMessage && <p>Error: {errorMessage}</p>}
      </Message.Content>
    </Message>
  )
);
