import React, { useEffect, useState } from "react";
import { store } from "services/state";
import { Auth } from "../services/AmplifyAuth";
import {
  Button,
  Form,
  Grid,
  Dimmer,
  Loader,
  Header,
  Menu,
  Icon,
  Segment,
  Confirm,
} from "semantic-ui-react";
import TOTPSetupFortm from "components/TOTPSetupFortm";
import { fragments } from "services/get-fragments";

export default () => {
  const [loading, setLoading] = useState();
  const [screen, setScreen] = useState(0);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [passwordLowerCase, setPasswordLowerCase] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordLong12, setPasswordLong12] = useState(false);
  const [preferredMFA, setPreferredMFA] = useState(undefined);

  const email = store.user && store.user.email;

  const submitChangePassword = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        alert(fragments.Session.passwordChanged);
        setScreen(0);
      } catch (error) {
        alert(error.message);
      }
      setLoading(false);
    })();
  };

  const handleChangeOldPassword = (event) => {
    setOldPassword(event.target.value);
  };

  const handleChangeNewPassword = (event) => {
    const regExpLowerCase = /[a-z]+/g;
    const regExpNumber = /\d/g;
    const regExpLong12 = /.{12,}/g;
    setPasswordLowerCase(regExpLowerCase.test(event.target.value));
    setPasswordNumber(regExpNumber.test(event.target.value));
    setPasswordLong12(regExpLong12.test(event.target.value));
    setNewPassword(event.target.value);
  };

  const resetMFA = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, "NOMFA");
      setPreferredMFA("NOMFA");
    } catch (error) {
      alert(error.message);
    }
  };

  const getPreferredMFA = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const ActualPreferredFMA = await Auth.getPreferredMFA(user);
    return ActualPreferredFMA;
  };

  useEffect(() => {
    (async () => {
      try {
        setPreferredMFA(await getPreferredMFA());
      } catch (error) {
        alert(error.message);
      }
    })();
  }, []);

  return (
    <>
      <Grid
        textAlign="center"
        style={{ height: "100vh", backgroundColor: "585134", marginTop: 0 }}
        columns={2}
        ti
      >
        <Grid.Column
          verticalAlign="middle"
          textAlign="left"
          style={{
            minHeight: "60vh",
            maxWidth: 300,
            backgroundColor: "lightgray",
            padding: 0,
          }}
        >
          <Segment secondary>
            <h3>{fragments.Session.accountSettings}</h3>
          </Segment>
          <Menu vertical fluid secondary>
            <Menu.Item
              name="info"
              active={screen === 0}
              onClick={() => setScreen(0)}
            >
              {fragments.Session.userData}
            </Menu.Item>
            <Menu.Item
              name="change_password"
              active={screen === 1}
              onClick={() => setScreen(1)}
            >
              {fragments.Session.changePassword}
            </Menu.Item>
            <Menu.Item
              name="reset_mfa"
              active={screen === 2}
              onClick={() => setScreen(2)}
            >
              {fragments.Session.resetMFA}
            </Menu.Item>
          </Menu>
        </Grid.Column>
        <Grid.Column
          textAlign="left"
          verticalAlign="middle"
          style={{
            minHeight: "60vh",
            maxWidth: 400,
            backgroundColor: "white",
            padding: 40,
          }}
        >
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
          {screen === 0 && (
            <Form>
              <Header>{fragments.Session.userData}</Header>
              <Form.Input label={`${fragments.Session.emailAddress}:`}>{email}</Form.Input>
            </Form>
          )}
          {screen === 1 && (
            <Form onSubmit={submitChangePassword}>
              <Header>
                {fragments.Session.changePassword}
              </Header>
              <Form.Input
                label={fragments.Session.previousPassword}
                value={oldPassword}
                type="password"
                icon="lock"
                iconPosition="left"
                onChange={handleChangeOldPassword}
              />
              <Form.Input
                label={fragments.Session.newPassword}
                value={newPassword}
                type="password"
                icon="lock"
                iconPosition="left"
                onChange={handleChangeNewPassword}
              />
              <div style={{ color: passwordLowerCase ? "green" : "red" }}>
                <Icon name={passwordLowerCase ? "checkmark" : "x"} />
                {fragments.Session.passwordPattern1}
              </div>
              <div style={{ color: passwordNumber ? "green" : "red" }}>
                <Icon name={passwordNumber ? "checkmark" : "x"} />
                {fragments.Session.passwordPattern2}
              </div>
              <div style={{ color: passwordLong12 ? "green" : "red" }}>
                <Icon name={passwordLong12 ? "checkmark" : "x"} />
                {fragments.Session.passwordPattern3}
              </div>
              <br />
              <Button primary fluid>
                {fragments.Session.changePassword}
              </Button>
            </Form>
          )}
          {screen === 2 && preferredMFA === "SOFTWARE_TOKEN_MFA" && (
            <Confirm
              open={true}
              cancelButton={fragments.common.cancel}
              confirmButton={fragments.common.confirm}
              onCancel={() => setScreen(1)}
              onConfirm={() => resetMFA()}
              content={fragments.Session.resetMfaConfirm}
            />
          )}
          {screen === 2 && preferredMFA === "NOMFA" && (
            <TOTPSetupFortm
              onVerifyTotpToken={async () => {
                setScreen(0);
                setPreferredMFA(await getPreferredMFA());
              }}
            />
          )}
        </Grid.Column>
      </Grid>
    </>
  );
};
