// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import * as AppClientService from "../services/appClient";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Icon, Container, Dimmer, Loader } from "semantic-ui-react";
import { fragments } from 'services/get-fragments'

export default (p) => {
  const { setModalTokenOpen, id } = p;
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);

  // Initial load
  useEffect(() => {
    (async function () {
      setLoading(true)
      try {
        const secret = await AppClientService.getSecret(id)
        const token = await AppClientService.generateToken(id, secret.ClientSecret);
        setToken(token)
      } catch (error) {
        alert(error.message);
      }
      setLoading(false)
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <p>{fragments.AppClient.tokenAdvice}</p>
      <CopyToClipboard text={token}>
        <Button primary>
          <Icon name="copy" /> {fragments.common.copy}
        </Button>
      </CopyToClipboard>
      <Button content={fragments.common.close} onClick={() => setModalTokenOpen(false)} />
    </Container>
  );
};
