exports.secondsFromUnitTime = (unitTime) => {
    switch (unitTime) {
      case "years":
        return 60 * 60 * 24 * 365;
      case "months":
        return 60 * 60 * 24 * 30;
      case "days":
        return 60 * 60 * 24;
      case "hours":
        return 60 * 60;
      case "minutes":
        return 60;
      case "seconds":
        return 1;
      default:
        return 1;
    }
  };
