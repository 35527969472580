// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import * as AppClientService from "../services/appClient";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as ParamsService from "../services/paramsService";
import * as ScopeService from "../services/scopeService";
import {
  Button,
  Icon,
  Container,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import _ from "lodash";
import { fragments } from "services/get-fragments";

const defaultValues = {
  IdTokenValidity: 60,
  FollowValidity: true,
  RefreshTokenValidity: 30,
  AccessTokenValidity: 60,
  TokenValidityUnits: {
    IdToken: "minutes",
    AccessToken: "minutes",
    RefreshToken: "days",
  },
};

export default (p) => {
  const { setModalFormOpen, id } = p;
  const [appClientName, setAppClientName] = useState("");
  const [appScopes, setAppScopes] = useState([]);
  const [clientSecret, setClientSecret] = useState(false);
  const [params, setParams] = useState(defaultValues);
  const [scopes, setScopes] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = async (event) => {
    if (/^\w+$/.test(event.target.value)) {
      setAppClientName(event.target.value);
    }
  };

  const handleChangeScopes = async (event, data) => {
    if (data.checked) {
      setAppScopes([...appScopes, data.name]);
    } else {
      _.remove(appScopes, (e) => {
        return e === data.name;
      });
      setAppScopes([...appScopes]);
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      if (!/^\w+$/.test(appClientName)) {
        throw Error(fragments.AppClient.error_name)
      }
      if (id) {
        await AppClientService.update({
          appClientId: id,
          appClientName: appClientName,
          appScopes: appScopes,
        });
        setLoading(false);
        setModalFormOpen(false);
      } else {
        const appClientCreated = await AppClientService.create({
          appClientName: appClientName,
          appScopes: appScopes,
        });
        setLoading(false);
        setClientSecret(appClientCreated.UserPoolClient.ClientSecret);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // Initial load
  useEffect(() => {
    (async () => {
      try {
        const params = await ParamsService.List();
        if (Object.keys(params).length === 0) {
          throw Error(fragments.AppClient.errorNoGLobal);
        }
        setParams(params.Item);
        const scopes = await ScopeService.List();
        setScopes(scopes);
        if (id) {
          const appClient = await AppClientService.Get(id);
          setAppClientName(appClient.Item.ClientName);
          if (appClient.Item.AllowedOAuthScopes) {
            setAppScopes(appClient.Item.AllowedOAuthScopes);
          }
          setParams(appClient.Item);
        }
      } catch (error) {
        setModalFormOpen(false);
        alert(error.message);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      {!clientSecret ? (
        <Form onSubmit={handleCreate}>
          <input type="hidden" value={id} />
          <Form.Field>
            <label htmlFor="appClientName">
              {fragments.AppClient.appName}:
            </label>
            <Form.Input
              name="appClientName"
              value={appClientName}
              onChange={handleChange}
              autoComplete="off"
              maxLength="20"
            />
          </Form.Field>
          <Form.Group inline>
            <Form.Field>
              <b>{fragments.AppClient.refreshToken}: </b>
              {params.RefreshTokenValidity}{" "}
              {params.TokenValidityUnits.RefreshToken}
            </Form.Field>
            <Form.Field>
              <b>{fragments.AppClient.accessToken}: </b>
              {params.AccessTokenValidity}{" "}
              {params.TokenValidityUnits.AccessToken}
            </Form.Field>
            <Form.Field>
              <b>{fragments.AppClient.idToken}: </b>
              {params.IdTokenValidity} {params.TokenValidityUnits.IdToken}
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <p>
                <b>{fragments.AppClient.oauthFlows}:</b>
              </p>
              <Form.Checkbox
                checked
                label={fragments.AppClient.clientCredentials}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <p>
                <b>{fragments.AppClient.oauthScopes}:</b>
              </p>
              {scopes.map((scope) => (
                <Form.Checkbox
                  key={scope.Scope}
                  name={scope.Scope}
                  label={`${scope.Scope} - ${scope.Description} `}
                  checked={_.includes(appScopes, scope.Scope)}
                  onChange={handleChangeScopes}
                />
              ))}
            </Form.Field>
          </Form.Group>
          <Button primary type="submit" positive content={id ? fragments.common.update : fragments.common.create} />
        </Form>
      ) : (
        <>
          <p>{fragments.AppClient.secretAdvice}</p>
          <CopyToClipboard text={clientSecret}>
            <Button primary>
              <Icon name="copy" /> {fragments.AppClient.copySecret}
            </Button>
          </CopyToClipboard>
          <Button content={fragments.common.close} onClick={() => setModalFormOpen(false)} />
        </>
      )}
    </Container>
  );
};
