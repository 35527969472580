// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";

// mobx
import * as cognitoGroupService from "../../services/cognitoGroup";
import {
  Button,
  Icon,
  Confirm,
  Table,
  Container,
  Modal,
  Popup,
  Header,
  Placeholder,
} from "semantic-ui-react";
import CognitoGroupForm from "./CognitoGroupForm";
import CognitoGroupUsersForm from "./CognitoGroupUsersForm";
import CognitoGroupApiForm from "./CognitoGroupApiForm";

// fragments
import { fragments } from "services/get-fragments";

export default () => {
  const [cognitoGroups, setCognitoGroups] = useState([]);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalEditApiOpen, setModalEditApiOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [key, setKey] = useState({});
  const [loading, setLoading] = useState(true);

  const confirmDelete = async (GroupName) => {
    setModalDeleteOpen(true);
    setKey({ GroupName: GroupName });
  };

  const openEditForm = async (GroupName) => {
    setModalEditOpen(true);
    setKey({ GroupName: GroupName });
  };

  const openEditApiForm = async (GroupName) => {
    setModalEditApiOpen(true);
    setKey({ GroupName: GroupName });
  };

  const handleDelete = async () => {
    setModalDeleteOpen(false);
    try {
      await cognitoGroupService.AdminDelete(key);
      const CognitoGroupToDelete = [...cognitoGroups];
      _.remove(CognitoGroupToDelete, function (n) {
        return n.GroupName === key.GroupName;
      });
      setCognitoGroups(CognitoGroupToDelete);
    } catch (error) {
      alert(error.message);
    }
  };

  // Initial load
  useEffect(() => {
    setLoading(true);
    cognitoGroupService.AdminList()
      .then((out) => {
        setCognitoGroups(out.Groups);
      })
      .finally(() => setLoading(false));
  }, [modalEditOpen, modalFormOpen]);

  return (
    <>
      <Confirm
        open={modalDeleteOpen}
        header={fragments.cognitoGroup.delete}
        onCancel={() => setModalDeleteOpen(false)}
        onConfirm={handleDelete}
        cancelButton={fragments.common.cancel}
        confirmButton={fragments.common.delete}
        content={fragments.cognitoGroup.confirmDelete}
      />
      <Container fluid style={{ padding: "2em" }}>
        <Header as="h1">{fragments.cognitoGroup.title}</Header>
        <Modal
          open={modalEditOpen}
          onClose={() => setModalEditOpen(false)}
          onOpen={() => setModalEditOpen(true)}
          onCancel={() => setModalEditOpen(false)}
        >
          <Modal.Header>{fragments.cognitoGroup.manage_users}</Modal.Header>
          <CognitoGroupUsersForm setModalFormOpen={setModalEditOpen} id={key} />
        </Modal>
        <Modal
          open={modalEditApiOpen}
          onClose={() => setModalEditApiOpen(false)}
          onOpen={() => setModalEditApiOpen(true)}
          onCancel={() => setModalEditApiOpen(false)}
        >
          <Modal.Header>{fragments.cognitoGroup.manage_apis}</Modal.Header>
          <CognitoGroupApiForm setModalFormOpen={setModalEditApiOpen} id={key} />
        </Modal>
        <div style={{ float: "right", marginBottom: "1rem" }}>
          <Button.Group>
            <Modal
              open={modalFormOpen}
              onClose={() => setModalFormOpen(false)}
              onOpen={() => setModalFormOpen(true)}
              onCancel={() => setModalFormOpen(false)}
              trigger={
                <Button primary>{fragments.cognitoGroup.create}</Button>
              }
            >
              <Modal.Header>
                {fragments.cognitoGroup.create}
              </Modal.Header>
              <CognitoGroupForm setModalFormOpen={setModalFormOpen} />
            </Modal>
          </Button.Group>
        </div>
        <div style={{ width: "100%" }}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.cognitoGroup.groupName}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.cognitoGroup.description}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.common.created}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.common.action}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  {[1, 2, 3, 4, 5, 6].map((n) => (
                    <Table.Cell key={n}>
                      <Placeholder fluid>&nbsp;</Placeholder>
                    </Table.Cell>
                  ))}
                </Table.Row>
              ) : cognitoGroups.length > 0 ? (
                cognitoGroups.map((group) => (
                  <Table.Row key={group.GroupName}>
                    <Table.Cell>{group.GroupName}</Table.Cell>
                    <Table.Cell>{group.Description}</Table.Cell>
                    <Table.Cell>
                      <Popup
                        content={(new Date(group.CreationDate)).toString()}
                        trigger={
                          <div>{moment(group.CreationDate).fromNow()}</div>
                        }
                      />
                    </Table.Cell>
                    <Table.Cell style={{ whiteSpace: "nowrap" }}>
                      <Button
                        onClick={() => openEditForm(group.GroupName)}
                      >
                        <Icon name="edit" />{fragments.cognitoGroup.manage_users}
                      </Button>
                      <Button
                        onClick={() => openEditApiForm(group.GroupName)}
                      >
                        <Icon name="edit" />{fragments.cognitoGroup.manage_apis}
                      </Button>
                      <Button
                        onClick={() => confirmDelete(group.GroupName)}
                      >
                        <Icon name="trash alternate" /> {fragments.common.delete}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="6">
                    {fragments.cognitoGroup.noGroups}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </Container>
    </>
  );
};
