// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import * as CognitoGroupService from "../../services/cognitoGroup";
import { apiGatewayClientWithCredentials } from 'services/api'

import {
  Button,
  Icon,
  Container,
  Form,
  Dimmer,
  Loader,
  List,
  Grid
} from "semantic-ui-react";
import { fragments } from 'services/get-fragments'

export default (p) => {
  const { setModalFormOpen, id } = p;
  const [apis, setApis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relatedApis, setRelatedApis] = useState([])
  const [reload, setReload] = useState(false)

  const excludeFromGroup = (apiId) => {
    CognitoGroupService.AdminRemoveApiFromGroup(id.GroupName, apiId).finally(
      setReload(!reload)
    )
  };

  const addToGroup = (apiId) => {
    CognitoGroupService.AdminAddApiToGroup({ GroupName: id.GroupName, apiId: apiId }).finally(
      setReload(!reload)
    )
  };

  // Initial load
  useEffect(() => {
    apiGatewayClientWithCredentials()
      .then(app => app.get('/admin/catalog/visibility', {}, {}, {}))
      .then(res => {
        var ApisFromGroup
        CognitoGroupService.AdminGetApiFromGroup(id.GroupName).then((related) => {
          ApisFromGroup = related;
          if (res.status === 200) {
            const apiGateway = res.data.apiGateway
            const generic = res.data.generic && Object.keys(res.data.generic)

            apiGateway.forEach(api => {
              for (let i = 0; i < ApisFromGroup.length; i++) {
                if (ApisFromGroup[i].ApiGatewayId === api.id) {
                  ApisFromGroup[i].name = api.name
                  ApisFromGroup[i].stage = api.stage
                  ApisFromGroup[i].visibility = api.visibility
                  for (let j = 0; j < res.data.apiGateway.length; j++) {
                    if (res.data.apiGateway[j].id === ApisFromGroup[i].ApiGatewayId) {
                      res.data.apiGateway.splice(j, 1)
                      j--
                    }
                  }
                }
              }
              if (generic) {
                generic.forEach(genApi => {
                  if (res.data.generic[`${genApi}`]) {
                    if (
                      res.data.generic[`${genApi}`].apiId === api.id &&
                      res.data.generic[`${genApi}`].stage === api.stage
                    ) {
                      api.visibility = true
                      delete res.data.generic[`${genApi}`]
                    }
                  }
                })
              }
            })
            setRelatedApis(ApisFromGroup)
            setApis(res.data.apiGateway)
            setLoading(false)
          }
        })
      })
    // eslint-disable-next-line
  }, [reload]);

  return (
    <Container style={{ padding: "1em" }}>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Form>
        <p>
          <b>{fragments.cognitoGroup.groupName}: </b>
        </p>
        <Form.Field>
          {id.GroupName}
        </Form.Field>
        <Grid columns={2}>
          <Grid.Column>
            <b>{fragments.cognitoGroup.related_apis}: {relatedApis.length}</b><hr />
            <List divided relaxed>
              {relatedApis.map((option) => (
                <List.Item key={option.ApiGatewayId + option.stage}>
                  <List.Icon name='code' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header as='a'>{option.name}</List.Header>
                    <List.Description as='a'>Id: {option.ApiGatewayId}, Stage: {option.stage}, Displayed: {option.visibility ? 'TRUE' : 'FALSE'}
                      <Button size="small" style={{ float: 'right' }} icon="chevron right" onClick={() => { setLoading(true); excludeFromGroup(option.ApiGatewayId) }} />
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
          <Grid.Column>
            <b>{fragments.cognitoGroup.available_apis} {apis.length}</b><hr />
            <List divided relaxed>
              {apis.map((option) => (
                <List.Item key={option.id + option.stage}>
                  <List.Icon name='code' size='large' verticalAlign='middle' />
                  <List.Content>
                    <List.Header as='a'>{option.name}</List.Header>
                    <List.Description as='a'>Id: {option.id}, Stage: {option.stage}, Displayed: {option.visibility ? 'TRUE' : 'FALSE'}
                      <Button size="small" style={{ float: 'right' }} icon="chevron left" onClick={() => { setLoading(true); addToGroup(option.id) }} />
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid>
        <hr />
        <Container style={{ padding: "1em", textAlign: "right" }}>
          <Button primary onClick={() => setModalFormOpen(false)}>
            <Icon name="close" /> {fragments.common.close}
          </Button>
        </Container>
      </Form>
    </Container>
  );
};
