// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";
import _ from "lodash";

// mobx
import * as apiTagService from "../../services/apiTag";
import {
  Button,
  Icon,
  Confirm,
  Table,
  Container,
  Modal,
  Header,
  Placeholder,
} from "semantic-ui-react";
import ApiTagForm from "./ApiTagForm";
import ApiTagApiForm from "./ApiTagApiForm";

// fragments
import { fragments } from "services/get-fragments";

export default () => {
  const [apiTags, setApiTag] = useState([]);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalEditApiOpen, setModalEditApiOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [key, setKey] = useState({});
  const [loading, setLoading] = useState(true);

  const confirmDelete = async (Tag) => {
    setModalDeleteOpen(true);
    setKey({ Tag: Tag });
  };

  const openEditApiForm = async (Tag) => {
    setModalEditApiOpen(true);
    setKey({ Tag: Tag });
  };

  const handleDelete = async () => {
    setModalDeleteOpen(false);
    try {
      await apiTagService.AdminDelete(key);
      const CognitoGroupToDelete = [...apiTags];
      _.remove(CognitoGroupToDelete, function (n) {
        return n.Tag === key.Tag;
      });
      setApiTag(CognitoGroupToDelete);
    } catch (error) {
      alert(error.message);
    }
  };

  // Initial load
  useEffect(() => {
    setLoading(true);
    apiTagService.AdminList()
      .then((out) => {
        setApiTag(out.Items);
      })
      .finally(() => setLoading(false));
  }, [modalFormOpen]);

  return (
    <>
      <Confirm
        open={modalDeleteOpen}
        header={fragments.ApiTag.delete}
        onCancel={() => setModalDeleteOpen(false)}
        onConfirm={handleDelete}
        cancelButton={fragments.common.cancel}
        confirmButton={fragments.common.delete}
        content={fragments.ApiTag.confirmDelete}
      />
      <Container fluid style={{ padding: "2em" }}>
        <Header as="h1">{fragments.ApiTag.title}</Header>
        <Modal
          open={modalEditApiOpen}
          onClose={() => setModalEditApiOpen(false)}
          onOpen={() => setModalEditApiOpen(true)}
          onCancel={() => setModalEditApiOpen(false)}
        >
          <Modal.Header>{fragments.ApiTag.manage_apis} {key.Tag}</Modal.Header>
          <ApiTagApiForm setModalFormOpen={setModalEditApiOpen} id={key} />
        </Modal>
        <div style={{ float: "right", marginBottom: "1rem" }}>
          <Button.Group>
            <Modal
              open={modalFormOpen}
              onClose={() => setModalFormOpen(false)}
              onOpen={() => setModalFormOpen(true)}
              onCancel={() => setModalFormOpen(false)}
              trigger={
                <Button primary>{fragments.ApiTag.create}</Button>
              }
            >
              <Modal.Header>
                {fragments.ApiTag.create}
              </Modal.Header>
              <ApiTagForm setModalFormOpen={setModalFormOpen} />
            </Modal>
          </Button.Group>
        </div>
        <div style={{ width: "100%" }}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.ApiTag.name}
                </Table.HeaderCell>
                <Table.HeaderCell collapsing sorted="ascending">
                  {fragments.common.action}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (
                <Table.Row>
                  {[1, 2].map((n) => (
                    <Table.Cell key={n}>
                      <Placeholder fluid>&nbsp;</Placeholder>
                    </Table.Cell>
                  ))}
                </Table.Row>
              ) : apiTags.length > 0 ? (
                apiTags.map((apiTag) => (
                  <Table.Row key={apiTag.Tag}>
                    <Table.Cell>{apiTag.Tag}</Table.Cell>
                    <Table.Cell style={{ whiteSpace: "nowrap" }}>
                      <Button
                        onClick={() => openEditApiForm(apiTag.Tag)}
                      >
                        <Icon name="edit" />{fragments.categories.manage_apis}
                      </Button>
                      <Button
                        onClick={() => confirmDelete(apiTag.Tag)}
                      >
                        <Icon name="trash alternate" /> {fragments.common.delete}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="6">
                    {fragments.categories.noCategories}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </Container>
    </>
  );
};
