import React, { useState } from "react";
import { Auth } from "../services/AmplifyAuth";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import { fragments } from "../services/get-fragments"

export default () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [deliveryDestination, setDeliveryDestination] = useState(false);
  const [passwordLowerCase, setPasswordLowerCase] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);
  const [passwordLong12, setPasswordLong12] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        const forgotPassword = await Auth.forgotPassword(username);
        setDeliveryDestination(forgotPassword.CodeDeliveryDetails.Destination);
        setWaitingForCode(true);
      } catch (error) {
        alert(`${fragments.Session.errorForgotPassword}: ${error.message}`);
      }
      setLoading(false);
    })();
  };

  const handleSubmitNewPassword = (event) => {
    event.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(username, code, password);
        alert(fragments.Session.passwordChanged);
        window.location = "/explorer";
      } catch (error) {
        alert(`${fragments.Session.errorPasswordSubmit}: ${error.message}`);
        setLoading(false);
      }
    })();
  };

  const handleChangeUser = (event) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event) => {
    const regExpLowerCase = /[a-z]+/g;
    const regExpNumber = /\d/g;
    const regExpLong12 = /.{12,}/g;
    setPasswordLowerCase(regExpLowerCase.test(event.target.value));
    setPasswordNumber(regExpNumber.test(event.target.value));
    setPasswordLong12(regExpLong12.test(event.target.value));
    setPassword(event.target.value);
  };

  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const pasteClipboard = async () => {
    const clipboardContent = await navigator.clipboard.readText();
    setCode(clipboardContent);
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh", backgroundColor: "dce1e9", marginTop: 0 }}
      columns={2}
    >
      <Grid.Column
        textAlign="left"
        verticalAlign="middle"
        style={{
          minHeight: "50vh",
          maxWidth: 400,
          backgroundColor: "white",
          padding: 40,
          borderRadius: 6, boxShadow: "0px 3px 9px rgba(0,0,0,0.17)"
        }}
      >
        <Dimmer active={loading} inverted>
          <Loader />
        </Dimmer>
        {!waitingForCode && (
          <Form onSubmit={handleSubmit}>
            <Header as="h1" textAlign="center">
              {fragments.Session.passwordRecovery}
            </Header>
            <p>
              {fragments.Session.passwordChangeInstructions}
            </p>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={fragments.Session.emailAddress}
              name="user"
              type="email"
              value={username}
              onChange={handleChangeUser}
            />
            <Button fluid primary>
              {fragments.Session.passwordRecovery}
            </Button>
          </Form>
        )}
        {waitingForCode && (
          <Form onSubmit={handleSubmitNewPassword}>
            <Header as="h1" textAlign="center">
              {fragments.Session.resetPasswordCode}
            </Header>
            <p>{`${fragments.Session.passwordResetInstructions} ${deliveryDestination}
              <br />${fragments.Session.passwordResetInstructions2}`}
            </p>
            <Form.Input
              fluid
              icon="key"
              iconPosition="left"
              placeholder={fragments.Session.code}
              name="code"
              type="number"
              value={code}
              onChange={handleChangeCode}
              action={{
                icon: "paste",
                labelPosition: "right",
                content: fragments.Session.paste,
                onClick: (event) => {
                  event.preventDefault();
                  pasteClipboard()
                },
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={fragments.Session.password}
              name="password"
              type="password"
              value={password}
              onChange={handleChangePassword}
            />
            <p>
              <div style={{ color: passwordLowerCase ? "green" : "red" }}>
                <Icon name={passwordLowerCase ? "checkmark" : "x"} />
                {fragments.Session.passwordPattern1}
              </div>
              <div style={{ color: passwordNumber ? "green" : "red" }}>
                <Icon name={passwordNumber ? "checkmark" : "x"} />
                {fragments.Session.passwordPattern2}
              </div>
              <div style={{ color: passwordLong12 ? "green" : "red" }}>
                <Icon name={passwordLong12 ? "checkmark" : "x"} />
                {fragments.Session.passwordPattern3}
              </div>
            </p>
            <Button fluid primary>
              {fragments.Session.changePassword}
            </Button>
          </Form>
        )}
      </Grid.Column>
    </Grid>
  );
};
